import { useEffect, useState } from 'react';
import { Form } from 'antd';

import moment from 'moment';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import PdTitle from 'components/pd-title';

import './style.scss';

/**
 *
 * @param {Object} props
 * @param {Function} props.onFinish function that recieve value of the log in form
 */

export default function VerificationOTP({ onFinish = () => {}, email = null }) {
  const [time, setTime] = useState(0);
  const [form] = Form.useForm();

  function countdown(interval = 5000) {
    return new Promise((resolve) => {
      let intervalLoop = null;

      function countTimer() {
        if (!localStorage.endTime) {
          localStorage.endTime = +new Date() + interval;
        }

        let remaining = localStorage.endTime - new Date();
        if (remaining >= 0) {
          let currentTime = Math.floor(remaining / 1000);
          setTime(currentTime);
        } else {
          clearInterval(intervalLoop);
          resolve(true);
        }
      }

      intervalLoop = setInterval(countTimer, 1000);
    });
  }

  function handleFinish(value) {
    if (!value) onFinish({ payload: {} });
    else {
      onFinish({ ...value, email });
    }
    form.resetFields();
  }

  const excFunct = async () => {
    await countdown(120000);
    localStorage.removeItem('endTime');
  };

  useEffect(() => {
    excFunct();
  }, []);

  return (
    <div className="pd-guest-modal-verif">
      <div className="header">
        <PdTitle color="black" align="center" size="small">
          OTP Authentication
        </PdTitle>
        We have sent you a verification code. Please check your email and enter
        the OTP from e-mail code
      </div>
      <div className="content">
        <Form
          className="form-wrapper"
          id="verif-form"
          layout="vertical"
          onFinish={(value) => handleFinish(value, 'verify')}
          requiredMark={false}
          form={form}>
          <Form.Item
            label="OTP Code"
            name="otp_code"
            rules={[
              {
                required: true,
                message:
                  'Please input your OTP that sent in your email address!',
              },
            ]}>
            <PdAntdInput
              type="number"
              placeholder="Enter OTP Code"
              className="pd-align-center"
            />
          </Form.Item>
          <Form.Item
            style={{
              textAlign: 'left',
              marginBottom: '1rem',
              marginTop: '1rem',
            }}>
            <PdButton htmlType="submit" style={{ width: '100%' }}>
              Auth
            </PdButton>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0, cursor: 'pointer' }}>
            {time > 0 && (
              <>
                <p>{moment.utc(time * 1000).format('HH:mm:ss')}</p>
              </>
            )}
            {time <= 0 && (
              <div
                onClick={() => {
                  handleFinish(false, 'resend');
                  excFunct();
                }}>
                Resend OTP Code
              </div>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
