import { Col, Row } from 'antd'


const data = [
  {
    id: 1,
    title: 'Resources',
    subTitle1: 'Explore Business',
    subTitle2: 'About Us',
    subTitle3: 'Join as Company'
  },
  {
    id: 2,
    title: 'Legal',
    subTitle1: 'Term & Condition',
    subTitle2: 'Privacy Policy',
  },
  {
    id: 3,
    title: 'FAQ',
    subTitle1: 'Crowdfunding',
    subTitle2: 'Our Contact',
  }
]

const Footer = () => {
  return (
    <>
      <Row align='top' justify='center'>
        <Col span={22}>
          <Row align='top' justify='space-between' className='pd-padding-top-md pd-padding-bottom-xl'>
            <Col span={14}>
              <p>Logo</p>
              <p>Lorem Ipsum dolor sit amet</p>
            </Col>
            <Col span={10}>
              <Row align='top' justify='center'>
                {data.map(i => (
                  <Col key={i.id} span={8}>
                    <h4 className='pd-margin-bottom-md font-bold'>{i.title}</h4>
                    <h4 className='font-300'>{i.subTitle1}</h4>
                    <h4 className='font-300'>{i.subTitle2}</h4>
                    <h4 className='font-300'>{i?.subTitle3}</h4>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row align='top' justify='center'>
        <Col span={22}>
          <p>© 2022 Your Company, Inc. All rights reserved.</p>
        </Col>
      </Row>
    </>
  )
}

export default Footer