import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Form } from '@ant-design/compatible';
import { Layout, message } from 'antd';

import { AuthenticationContext } from 'contexts/authentication';

import AuthenticationApi from 'api/authentication';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import Forgot from 'components/pd-modal/forgot';
import Password from 'components/pd-modal/set-password';
import VerificationOTP from 'components/pd-modal/verified';

import './style.scss';

const { Content } = Layout;

export function LoginPage({ form, history }) {
  const { auth } = useContext(AuthenticationContext);
  const loc = useLocation();
  const loginState = loc.state ? loc.state.status : undefined;

  const [loadingButton, seLoadingButton] = useState(false);
  const [modal, setModal] = useState({ type: '', visible: false });

  const { getFieldDecorator } = form;

  const location =
    history.location.pathname === '/register' ? 'register' : 'login';

  async function handleLogin(payload) {
    try {
      seLoadingButton(true);
      if (location === 'register') {
        await auth(payload, 'register', 'company');
      } else {
        await auth(payload, 'login', 'company');
        history.push({
          pathname: '/company/dashboard',
        });
      }
    } catch (err) {
      if (err.response.data.message) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      seLoadingButton(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        handleLogin(values);
      }
    });
  }

  const handleForgotPassword = () => {
    setModal({ type: 'forgot', visible: true });
  };

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleSubmitForgotPassword = async (type = '', value) => {
    if (type === 'forgot') {
      try {
        await AuthenticationApi.forgotPassword(value);
        setModal({ type: 'otp', visible: true, data: value });
        await AuthenticationApi.otpVerif(value);
        setModal({ type: 'setPassword', visible: true });
      } catch (err) {
        message.error(err.message);
      } finally {
        setModal({ type: '', visible: false });
      }
    }
  };

  const modalContent = {
    forgot: (
      <Forgot
        onFinish={(value) => handleSubmitForgotPassword('forgot', value)}
      />
    ),
    otp: (
      <VerificationOTP
        onFinish={(value) => handleSubmitForgotPassword('forgot', value)}
        email={modal.data}
      />
    ),
    setPassword: <Password />,
  };

  useEffect(() => {
    if (loginState) message.success(loginState);
  }, []);

  return (
    <Content className="pd-login">
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <div className="login-wrapper">
        <div className="login-content">
          <div className="logo-container pd-margin-bottom-lg">
            {location === 'register' ? 'Register' : 'Login'}
          </div>
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Please input your email!' },
                ],
              })(<PdAntdInput variant="email" placeholder="Email" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: 'Please input your Password!' },
                ],
              })(<PdAntdInput variant="password" placeholder="Password" />)}
              {location === 'login' && (
                <p className="pd-align-right" onClick={handleForgotPassword}>
                  Forgot your password?
                </p>
              )}
            </Form.Item>
            {/* register account field */}
            {location === 'register' && (
              <>
                <Form.Item>
                  {getFieldDecorator('full_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Fullname!',
                      },
                    ],
                  })(<PdAntdInput variant="text" placeholder="Fullname" />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('company_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Company Name!',
                      },
                    ],
                  })(<PdAntdInput variant="text" placeholder="Company Name" />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('brand_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Brand Name!',
                      },
                    ],
                  })(<PdAntdInput variant="text" placeholder="Brand Name" />)}
                </Form.Item>
              </>
            )}
            <Form.Item>
              <PdButton
                color="blue"
                htmlType="submit"
                style={{ width: '100%' }}
                loading={loadingButton}>
                {location === 'register' ? 'Register' : 'Login'}
              </PdButton>
              {location === 'register' ? (
                <p>
                  Have account?
                  <Link to="/login"> Login Here</Link>
                </p>
              ) : (
                <p>
                  Not registered yet?{' '}
                  <Link to="register">Create an Account</Link>
                </p>
              )}
            </Form.Item>
          </Form>
        </div>

        <div className="login-bg"></div>
      </div>
    </Content>
  );
}

export const LoginPageForm = Form.create({ name: 'login' })(LoginPage);

export default { LoginPage };
