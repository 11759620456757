import { Col, Row } from 'antd';
import { PdButton } from 'components/pd-button';


const JoinOurBusinessOwner = ({ className }) => {
  return (
    <Row
      className={className}
      align="middle"
      justify="center"
      style={{
        backgroundColor: '#F8F8F8',
        height: 232,
      }}>
      <Col span={22}>
        <Row
          align="middle"
          justify="space-between"
          className="pd-padding-top-md pd-padding-bottom-md">
          <Col span={12}>
            <h3 style={{ maxWidth: 240 }} className="font-bold pd-text-primary">
              Lorem Ipsum Dolor sit Amet
            </h3>
            <p className="font-300" style={{ maxWidth: 500, color: '#8e8e8e' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              venenatis odio nec risus pellentesque vehicula.
            </p>
          </Col>
          <Col span={12}>
            <Row align="middle" justify="end">
              <Col span={10}>
                <PdButton
                  type={'primary'}
                  className="pd-button-primary h-button-primary">
                  Join as Business Owner
                </PdButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default JoinOurBusinessOwner;
