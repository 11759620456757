import './style.scss';

/**
 * This component is used for adding a white background in the dashboard view
 */
export function DashboardContainer({ children, ...props }) {
  return (
    <div className="pd-cms-content" {...props}>
      {children}
    </div>
  );
}

export default { DashboardContainer };
