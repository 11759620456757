import './style.scss';
import { useEffect, useState } from 'react';
import { Alert, Layout, Steps } from 'antd';
import { Link } from 'react-router-dom';

import { useAuthContext } from 'contexts/authentication';

import { DashboardContainer } from 'components/dashboard-container';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import BusinessType from 'components/pd-modal/business-type';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';

const { Step } = Steps;

export function Home() {
  const { authenticatedUserProfile, auth } = useAuthContext();
  const { status: userStatus, isHavingBussines } = authenticatedUserProfile;

  const [timeline, setTimeline] = useState(0);

  const [modal, setModal] = useState({ type: '', visible: false });

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleOpenModal = () => {
    setModal({ type: 'businessType', visible: true });
  };

  const checkUserStatus = async () => {
    await auth({}, 'getUserProfile');
    if (userStatus === 'not verified') setTimeline(0);
    if (userStatus === 'pending') setTimeline(1);
    if (userStatus === 'verified') setTimeline(2);
    if (isHavingBussines) setTimeline(3);
  };

  const modalContent = {
    businessType: <BusinessType />,
    // success: <Success />,
  };

  useEffect(() => {
    checkUserStatus();
  }, []);
  return (
    <Layout className="pd-cms-home">
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Dashboard
        </PdTitle>
        <PdButton
          onClick={handleOpenModal}
          disabled={userStatus !== 'verified'}>
          Register Your Business
        </PdButton>
      </PdAntdHeader>
      <DashboardContainer>
        <div className="steps-wrapper">
          <Steps size="small" current={timeline} labelPlacement="vertical">
            <Step title="Verify Your Account" />
            <Step title="Reviewing Account" />
            <Step title="Register Your Business" />
            <Step title="Upload Company Document" />
          </Steps>
        </div>
        {userStatus === 'not verified' && (
          <Alert
            message="Your account hasn’t been confirmed yet."
            type="info"
            description="please verify your account"
            showIcon
            action={
              <Link to="/company/verify-account">
                <PdButton color="white">Verify Your Account</PdButton>
              </Link>
            }
          />
        )}
      </DashboardContainer>
    </Layout>
  );
}

export default { Home };
