import dashboard from '../../assets/icon/dashboard-icon.svg';
import revenue from '../../assets/icon/revenue-icon.svg';
import companyDoc from '../../assets/icon/document-icon.svg';
import blog from '../../assets/icon/blog.svg';
export const menuOptionsCompany = [
  {
    type: 'Menu',
    name: 'Dashboard',
    link: '/company/dashboard',
    image: dashboard,
  },
  {
    type: 'Menu',
    name: 'Revenue Disbursement',
    link: '/company/revenue-disbursement',
    image: revenue,
  },
  {
    type: 'Menu',
    name: 'Company Document',
    link: '/company/company-document',
    image: companyDoc,
  },
  {
    type: 'Menu',
    name: 'Blog',
    link: '/company/blog',
    image: blog,
  },
];

export const menuOptionsInvestor = [
  {
    type: 'Menu',
    name: 'My Business',
    link: '/investor/my-business',
    image: revenue,
  },
  {
    type: 'Menu',
    name: 'History',
    link: '/investor/history',
    image: companyDoc,
  },
  {
    type: 'Menu',
    name: 'Favorite',
    link: '/investor/favorite',
    image: blog,
  },
];
