import { Input } from 'antd'

const TextInput = ({ className, size, type, name, placeholder, value, onChange, prefix }) => {
  return (
    <Input
      style={{ borderRadius: '8px' }}
      className={className}
      type={type}
      name={name}
      size={size}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      prefix={prefix}
    />
  )
}

export default TextInput