import { Route, Redirect } from 'react-router-dom';

/**
 *
 * @param {object} props
 * @param {any} props.component
 * @param {string} props.path
 * @returns
 */
function AuthenticatedGuardRoute(props) {
  const {
    component: WrapperComponent,
    path,
    destinationToRedirect = '/login',
    ...rest
  } = props;
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return localStorage.getItem('company_token') ||
          localStorage.getItem('wallet_address') ? (
          <WrapperComponent {...props} />
        ) : (
          <Redirect to={destinationToRedirect} />
        );
      }}
    />
  );
}

export default AuthenticatedGuardRoute;
