import { Col, Form, Row } from 'antd';
import { Link } from 'react-router-dom';

import PdTitle from 'components/pd-title';

import './style.scss';


/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

export default function BusinessType({ onFinish = () => {} }) {
  return (
    <div className="pd-guest-modal-business">
      <div className="header">
        <PdTitle align="center" color="black" size="small">
          Your Type of Business?
        </PdTitle>
      </div>
      <div className="content">
        <Form
          id="forgot-form"
          layout="vertical"
          className="form-wrapper"
          onFinish={onFinish}
          requiredMark={false}>
          <Form.Item>
            <Row justify="center" gutter={20}>
              <Col>
                <Link to="/company/existing-business">
                  <div>Existing Business</div>
                </Link>
              </Col>
              <Col>
                <Link to="/company/fundraising-business">
                  <div>Fundraising Business</div>
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
