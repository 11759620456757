import api from './index';
import token from './getAccessToken';

export default {
  loginCompany(payload) {
    return api.post(`/company/login`, payload);
  },
  registerCompany(payload) {
    return api.post(`/company/register`, payload);
  },
  logout(access_token) {
    return api.delete(`/admins/logout`, { data: { access_token } });
  },
  forgotPassword(payload) {
    return api.post(
      '/company/forgot_password',
      payload,
      token.getAccessToken('company')
    );
  },
};
