import { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { message } from 'antd';

import AuthenticationApi from 'api/authentication';
import CompanyAPI from 'api/CompanyAPI';

export const AuthenticationContext = createContext();
export const useAuthContext = () => useContext(AuthenticationContext);

/**
 *
 * @param {object} props
 * @param {any} props.children
 * @returns
 */
function AuthenticationContextProvider({ children }) {
  const history = useHistory();
  const userProfile = JSON.parse(localStorage.getItem('current_user'));

  const [authenticated, setAuthenticated] = useState(false);
  const [authenticatedUserProfile, setAuthenticatedUserProfile] =
    useState(userProfile);

  async function auth(payload, action = '', useFor = '') {
    let response = {};
    switch (action) {
      case 'login':
        if (useFor === 'company') {
          response = await AuthenticationApi.loginCompany(payload);
        } else {
          // response = await AuthenticationApi.loginInvestor(payload);
        }

        break;

      case 'register':
        if (useFor === 'company') {
          try {
            response = await AuthenticationApi.registerCompany(payload);
          } catch (err) {
            message.error(err.response.data.messages[0]);
          }
        } else {
          // response = await AuthenticationApi.loginInvestor(payload);
        }

        break;

      case 'getUserProfile':
        try {
          response = await CompanyAPI.getUserProfile();
        } catch (err) {
          message.error(err.response.data.messages[0]);
        }

        break;
    }

    const {
      access_token: accessToken,
      brand_name,
      company_name,
      email,
      full_name,
      id,
      status,
      userType,
      isHavingBussines,
    } = response?.data ?? {};

    const userProfile = {
      brandName: brand_name,
      companyName: company_name,
      email,
      fullName: full_name,
      id,
      status,
      userType,
      isHavingBussines,
    };

    if (response.statusText === 'Created') {
      history.push({
        pathname: '/login',
        state: { status: 'Register Success', data: '' },
      });
    }

    // or
    // const accessToken = data?.data?.access_token

    if (action !== 'getUserProfile' && accessToken && userProfile) {
      localStorage.setItem('company_token', accessToken);
      localStorage.setItem('current_user', JSON.stringify(userProfile));

      setAuthenticated(true);
      setAuthenticatedUserProfile(userProfile);
    } else if (action === 'getUserProfile' && userProfile) {
      localStorage.setItem('current_user', JSON.stringify(userProfile));
      setAuthenticatedUserProfile(userProfile);
    }

    // window.location.reload();
  }

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        authenticatedUserProfile,
        auth,
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationContextProvider;
