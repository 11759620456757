import api from '../index';
import token from '../getAccessToken';

export default {
  verifUser(payload) {
    return api.post(
      `/company/form-verification`,
      payload,
      token.getAccessToken('company')
    );
  },
  getUserProfile() {
    return api.get(`/company/profile`, token.getAccessToken('company'));
  },
  createExistingBusiness(payload) {
    return api.post(
      `/company/bussines/existing`,
      payload,
      token.getAccessToken('company')
    );
  },
  createFundrisingBusiness(payload) {
    return api.post(
      `/company/bussiness/fund-raising`,
      payload,  
      token.getAccessToken('company')
    );
   },
   
  getInitialForms(params) {
    return api.get(
      `/company/form-verification/${params}`,
      token.getAccessToken('company')
    );
  },
};
