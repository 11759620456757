import { createRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Layout, Row, message, Typography } from 'antd';

import moment from 'moment';

import { DashboardContainer } from 'components/dashboard-container';
import { PdAntdDatePicker } from 'components/pd-antd-date-picker';
import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import AskVerif from 'components/pd-modal/verif-ask';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import PdUploader from 'components/pd-uploader';

import CompanyAPI from 'api/CompanyAPI';

import { useAuthContext } from 'contexts/authentication';

const { Text } = Typography;

const halfLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
  labelAlign: 'left',
};

export function VerifyForm() {
  const ref = createRef();
  const history = useHistory();
  const [form] = Form.useForm();
  const { authenticatedUserProfile } = useAuthContext();
  const { companyName, fullName } = authenticatedUserProfile;

  const [loading, setLoading] = useState(false);
  const [videoProfile, setVideoProfile] = useState(null);
  const [modal, setModal] = useState({ type: '', visible: false });
  const [formValues, setFormValues] = useState({});

  const isNotFirstFilled = JSON.parse(localStorage.getItem('form_id'));

  async function getInitialForms() {
    const formId = isNotFirstFilled;
    const { data } = await CompanyAPI.getInitialForms(formId);
    const {
      full_name,
      birth_day,
      no_ktp,
      selfie_ktp = '',
      company_name,
      npwp,
      company_website,
      company_social_media,
    } = data;

    if (isNotFirstFilled) {
      form.setFieldsValue({
        full_name,
        company_website,
        company_social_media,
        birth_day: moment(birth_day),
        no_ktp,
        selfie_ktp,
        company_name,
        npwp,
      });
    } else {
      form.setFieldsValue({
        full_name: fullName,
        company_name: companyName,
      });
    }
  }

  async function handleSubmit(values) {
    setLoading(true);
    // const { data } = await CompanyAPI.getUserProfile();

    try {
      const {
        full_name,
        birth_day,
        no_ktp,
        selfie_ktp = '',
        company_name,
        npwp,
        company_website,
        company_social_media,
      } = values;

      const payload = {
        full_name,
        birth_day: moment(birth_day).format('YYYY-MM-DD'),
        no_ktp,
        selfie_ktp,
        company_name,
        npwp,
        company_website,
        company_social_media,
      };

      const {
        data: { id },
      } = await CompanyAPI.verifUser(payload);
      localStorage.setItem('form_id', JSON.stringify(id));

      // got 500
      // const { dataResUser } = await CompanyAPI.getUserProfile();

      message.success('Data has successfully sent');
      // history.push('/company/dashboard');
    } catch (err) {
      console.log(err);
      message.error(err?.response?.data?.message);
    } finally {
      handleCloseModal();
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleSetFormValues = (value) => {
    setFormValues(value);
    setModal({ type: 'verification', visible: true });
  };

  const handleVerifAnswer = (val) => {
    if (val === 'yes') {
      handleSubmit(formValues);
    } else {
      handleCloseModal();
    }
  };

  const modalContent = {
    verification: <AskVerif onFinish={handleVerifAnswer} />,
    // success: <Success />,
  };

  useEffect(() => {
    getInitialForms();
  }, []);

  return (
    <Layout>
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader>
        <PdTitle size="small" className="pd-margin-top-sm">
          Verify Account
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer className="pd-cms-content">
        <Form form={form} onFinish={handleSetFormValues}>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">Personal Information</PdTitle>
                <p>This section will be provide</p>
              </div>
              <Form.Item
                {...halfLayout}
                className=""
                label="Full Name"
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your fullname!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Full Name"
                  ref={ref}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Birthday"
                name="birth_day"
                rules={[
                  {
                    required: true,
                    message: 'Please input your birth date!',
                  },
                ]}>
                <PdAntdDatePicker
                  ref={ref}
                  placeholder="Birthday date"
                  className="w-quarter"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="No KTP"
                name="no_ktp"
                rules={[
                  {
                    required: true,
                    message: 'Please input your KTP number!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write KTP number"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Selfie with KTP"
                name="selfie_ktp"
                rules={[
                  {
                    required: false,
                    message: 'Please input your selfie with KTP!',
                  },
                ]}>
                <PdUploader
                  accept="video/mp4"
                  type="image"
                  afterUpload={setVideoProfile}
                  url={videoProfile}
                  purpose="ktp_selfie"
                />
                <div className="pd-margin-top-sm pd-cms-teacher-create-warning">
                  <Text>* max upload size</Text>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">Company Information</PdTitle>
                <p>This section will be provide</p>
              </div>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company Name"
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company name!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Company Name"
                  ref={ref}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company NPWP"
                name="npwp"
                rules={[
                  {
                    required: true,
                    message: 'Please input your NPWP number!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write NPWP number"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company Website"
                name="company_website"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company website url!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Company Website URL"
                  ref={ref}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company Social Media"
                name="company_social_media">
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Company Social Media"
                  ref={ref}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={20} className="pd-margin-bottom-xl">
            <Col span={3} style={{ justifyContent: 'end', display: 'flex' }}>
              <PdButton
                type="default"
                className="w-full"
                color="blue"
                onClick={() => history.goBack()}>
                Cancel
              </PdButton>
            </Col>
            <Col span={3} style={{ justifyContent: 'end', display: 'flex' }}>
              <PdButton
                htmlType="submit"
                color="purple"
                loading={loading}
                disabled={isNotFirstFilled}
                className="w-full">
                Verify Now
              </PdButton>
            </Col>
          </Row>
        </Form>
      </DashboardContainer>
    </Layout>
  );
}

export default { VerifyForm };
