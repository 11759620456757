import { Col, Row } from 'antd';
import CardNFT from '../CardNFT';
import dataCardNFT from 'pages/landing/Explore/dataCardNFT';
import BgCard from 'assets/images/bg-card.png';

const AllCardNFT = () => {
  return (
    <Row
      align="middle"
      justify="center"
      className="pd-margin-top-md pd-margin-bottom-xl">
      <Col span={22}>
        <Row justify="space-between" gutter={[16, 16]}>
          {dataCardNFT.map((data) => (
            <Col span={8} className="gutter-row" key={data.id}>
              <CardNFT
                className={'pd-margin-top-lg'}
                cover={
                  <img
                    src={BgCard}
                    alt="bg-card"
                    style={{ borderRadius: '12px 12px 0 0' }}
                  />
                }
                data={data}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default AllCardNFT;
