import { createRef, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Layout, Row, message, Typography } from 'antd';

import moment from 'moment';

import { DashboardContainer } from 'components/dashboard-container';
import { PdAntdDatePicker } from 'components/pd-antd-date-picker';
import { PdAntdSelect } from 'components/pd-antd-select';
import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import AskVerif from 'components/pd-modal/verif-ask';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import PdUploader from 'components/pd-uploader';

import CompanyAPI from 'api/CompanyAPI';
import GuestAPI from 'api/GuestAPI';

const { Text } = Typography;

const halfLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
  labelAlign: 'left',
};

export function CreateBusiness() {
  const ref = createRef();
  const history = useHistory();
  const businessType =
    history.location.pathname.indexOf('existing') > 0
      ? 'Existing'
      : 'Fundraising';

  const [avatar, setAvatar] = useState(null);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [videoProfile, setVideoProfile] = useState(null);
  const [modal, setModal] = useState({ type: '', visible: false });

  async function handleSubmit(values) {
    setLoading(true);
    try {
      const {
        brand_desc,
        brand_location,
        brand_name,
        category_id,
        company_name,
        cont_company,
        cont_tykoon,
        expected_apy,
        fair_value,
        logo = '',
        monthly_revenue,
        number_of_nft,
        price_of_nft,
        total_fund,
        launch_date,
      } = values;

      const payload = {
        brand_description: brand_desc,
        branch_location: brand_location,
        brand_name,
        category_id,
        company_name,
        contribution_company: cont_company,
        contribution_tykoon: cont_tykoon,
        expected_apy,
        fair_value,
        logo,
        monthly_revenue,
        number_of_nft,
        price_of_nft,
        fund_goal: total_fund,
        launch_date: moment(launch_date).format('YYYY-MM-DD'),
        dividend_period: 0,
      };

      const { data } =
        businessType === 'Existing'
          ? await CompanyAPI.createExistingBusiness(payload)
          : await CompanyAPI.createFundrisingBusiness(payload);
      message.success('Business successfully created, waiting for approval!');
      history.goBack()
    } catch (err) {
      console.log(err);
      message.error(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const catList = useMemo(() => {
    const output = categories.map(({ name, id }) => ({
      text: name,
      value: id,
    }));

    return [...output];
  }, [categories]);

  const handleChangeCat = (val) => {
    setCategory(val);
  };

  const getCategories = async () => {
    const { data } = await GuestAPI.getCategories();
    setCategories(data);
  };

  const modalContent = {
    verification: <AskVerif />,
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Layout>
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader>
        <PdTitle size="small" className="pd-margin-top-sm">
          {businessType} Business
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer className="pd-cms-content">
        <Form onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">
                  Business Detail Information
                </PdTitle>
                <p>This section will be provide</p>
              </div>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company Name"
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company name!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Company Name"
                  ref={ref}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Business Name"
                name="brand_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your business name!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Business Name"
                  ref={ref}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Business Desc"
                name="brand_desc"
                rules={[
                  {
                    required: true,
                    message: 'Please input your business desc!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Business Description"
                  ref={ref}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Business Categories"
                name="category_id"
                rules={[
                  {
                    required: true,
                    message: 'Please input your business categories!',
                  },
                ]}>
                <PdAntdSelect
                  ref={ref}
                  dataSet={catList}
                  optKey="value"
                  optValue="value"
                  optContent="text"
                  defaultValue={undefined}
                  placeholder="Select Business Categories"
                  className="w-full"
                  onSelect={handleChangeCat}
                  value={category}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Brand Location"
                name="brand_location"
                rules={[
                  {
                    required: true,
                    message: 'Please input your brand location!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Brand Location"
                  ref={ref}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Your Brand Logo"
                name="logo"
                rules={[
                  {
                    required: false,
                    message: 'Please input your brand logo!',
                  },
                ]}>
                <PdUploader
                  accept="video/mp4"
                  type="image"
                  afterUpload={setVideoProfile}
                  url={videoProfile}
                  purpose="logo_brand"
                />
                <div className="pd-margin-top-sm pd-cms-teacher-create-warning">
                  <Text>* max upload size</Text>
                </div>
              </Form.Item>
              {businessType === 'Existing' ? (
                <Form.Item
                  {...halfLayout}
                  className=""
                  label="Avg Monthly Revenue"
                  name="monthly_revenue"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your avg monthly revenue!',
                    },
                  ]}>
                  <PdAntdInput
                    disabled={loading}
                    variant="number"
                    ref={ref}
                    placeholder="Write Avg Revenue"
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  {...halfLayout}
                  className=""
                  label="Expect Monthly Revenue"
                  name="monthly_revenue"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your expect monthly revenue!',
                    },
                  ]}>
                  <PdAntdInput
                    disabled={loading}
                    variant="number"
                    ref={ref}
                    placeholder="Write Expect Revenue"
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">NFT Disbursement</PdTitle>
                <p>This section will be provide</p>
              </div>

              <Form.Item
                {...halfLayout}
                className=""
                label="Number of NFT"
                name="number_of_nft"
                rules={[
                  {
                    required: true,
                    message: 'Please input your number of NFT!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write number of NFT"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Price of NFT"
                name="price_of_nft"
                rules={[
                  {
                    required: true,
                    message: 'Please input your price of NFT!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write price of NFT"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Expected APY"
                name="expected_apy"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Expected APY!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write Expected APY"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Fair Value"
                name="fair_value"
                rules={[
                  {
                    required: true,
                    message: 'Please input your fair value!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write Fair Value"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">Your Timeline</PdTitle>
                <p>This section will be provide</p>
              </div>

              <Form.Item
                {...halfLayout}
                className=""
                label="Launch Date"
                name="launch_date"
                initialValue={moment()}
                rules={[
                  {
                    required: true,
                    message: 'Please input your launch date!',
                  },
                ]}>
                <PdAntdDatePicker
                  ref={ref}
                  placeholder="Company launch date"
                  className="w-quarter"
                />
              </Form.Item>

              <Form.Item
                {...halfLayout}
                className=""
                label="Contribution of Company"
                name="cont_company"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Contribution of Company!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write Contribution of Company"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Contribution of Tykoon"
                name="cont_tykoon"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Contribution of Tykoon!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write Contribution of Tykoon"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Total Fund Needed"
                name="total_fund"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Total Fund Needed!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write Total Fund Needed"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={20} className="pd-margin-bottom-xl">
            <Col span={3} style={{ justifyContent: 'end', display: 'flex' }}>
              <PdButton
                type="default"
                className="w-full"
                color="blue"
                onClick={() => history.goBack()}>
                Cancel
              </PdButton>
            </Col>
            <Col span={3} style={{ justifyContent: 'end', display: 'flex' }}>
              <PdButton
                htmlType="submit"
                color="purple"
                loading={loading}
                className="w-full">
                Submit
              </PdButton>
            </Col>
          </Row>
        </Form>
      </DashboardContainer>
    </Layout>
  );
}

export default { CreateBusiness };
