import { Typography } from 'antd';

import './style.scss';

const { Title } = Typography;

/**
 *
 * @param {Object} props
 * @param {('left' | 'center' | 'right')} props.align align, default 'left'
 * @param {('black' | 'blue' | 'gray' | 'white' )} props.color color, default 'gray'
 * @param {('none' | 'large' | 'xlarge')}  props.margin bottom margin, default ''
 * @param {('normal' |'bold' | 'bolder')} props.weight weight, default ''
 * @param {('large' | 'normal' | 'small' | 'extra-small')} props.size size, default ''
 *
 */

export default function PdTitle({
  align = 'left',
  color = 'gray',
  margin = '',
  weight = '',
  size = '',
  children,
  className = '',
  ...props
}) {
  return (
    <Title
      {...props}
      className={`pd-title pd-title-color-${color} pd-title-align-${align} pd-title-margin-${margin} pd-title-weight-${weight} pd-title-size-${size} ${className}`}>
      {children}
    </Title>
  );
}
