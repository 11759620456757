import { Switch } from 'react-router-dom';
import { Layout } from 'antd';

import { useScrollToTop } from 'hooks/use-scroll-top';

import AuthenticatedGuardRoute from 'components/guard/authenticated';
import { Sidebar } from 'components/sidebar';
import { PdNavbar } from 'components/pd-navbar';

import { CreateBusiness } from 'pages/dashboard/Company/business-create';
import { Home } from 'pages/dashboard/Company/company-home';
import { VerifyForm } from 'pages/dashboard/Company/verify-account';


const CompanyRouteWrapper = () => {
  useScrollToTop();
  return (
    <Switch>
      <>
        <Layout className="pd-cms">
          <Sidebar from="company" />
          <Layout>
            <PdNavbar />
            <AuthenticatedGuardRoute
              exact
              path="/company/dashboard"
              component={Home}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/revenue-disbursement"
              component={Home}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/verify-account"
              component={VerifyForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/existing-business"
              component={CreateBusiness}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/fundraising-business"
              component={CreateBusiness}
            />
          </Layout>
        </Layout>
      </>
    </Switch>
  );
};

export default CompanyRouteWrapper;
