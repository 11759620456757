import { Modal } from 'antd';

import './style.scss';

/**
 *
 * @param {Object} props
 * @param {VoidFunction} props.handleClose function to hide modal, default () => {}
 * @param {Boolean} props.visible boolean value that define whether visible or not, default false
 * @param {Boolean} props.closable boolean value that define whether closable or not, default true
 */

 export function PdModals({
  children,
  handleClose = () => {},
  visible = false,
  closable = true,
}) {
  return (
    <Modal
      centered
      destroyOnClose={true}
      footer={null}
      onCancel={handleClose}
      visible={visible}
      closable={closable}
      wrapClassName="pd-guest-modal">
      {children}
    </Modal>
  );
}