import { Layout } from 'antd';

import './style.scss';

const { Header } = Layout;

export default function PdAntdHeader({ children, ...props }) {
  return (
    <Header className="pd-antd-header" {...props}>
      {children}
    </Header>
  );
}
