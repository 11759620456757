const initialHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export default {
  /**
   *
   * @param {('company' | 'investor',)} person
   * @returns
   */
  getAccessToken(person) {
    let access_token = localStorage.getItem(`${person}_token`);

    if (access_token) {
      return {
        headers: {
          Authorization: `Bearer ${access_token}`,
          ...initialHeaders,
        },
      };
    } else {
      return {
        headers: initialHeaders,
      };
    }
  },
};
