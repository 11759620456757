import AllCardNFT from 'components/landing-components/AllCard';
import Footer from 'components/landing-components/Footer';
import Hero from 'components/landing-components/Hero';
import JoinOurBusinessOwner from 'components/landing-components/JoinBusinessOwner';
import Navbar from 'components/landing-components/Navbar';


export const Explore = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <AllCardNFT />
      <JoinOurBusinessOwner className={'pd-margin-bottom-lg'} />
      <Footer />
    </>
  );
};
