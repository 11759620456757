import { Col, Row, Select } from 'antd'
import './style.scss'
const { Option } = Select

const TSelect = ({ className, onChange, options, defaultValue, label }) => {
  return (
    <Row>
      <Col span={24} style={{ marginBottom: 8 }}>
        <label>{label}</label>
      </Col>
      <Col span={24} className='h-full'>
        <Select
          style={{ height: 48 }}
          defaultValue={defaultValue}
          className={className}
          onChange={onChange}
        >
          {options && options.map((ops, index) => (
            <Option key={index} value={ops.value}>{ops.label}</Option>
          ))}
        </Select>
      </Col>
    </Row>
  )
}

export default TSelect