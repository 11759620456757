import TextInput from '../TextInput';
import { Col, Row } from 'antd';

export const TextInputWithLabel = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  label,
  className,
}) => {
  return (
    <Row>
      <Col span={24} style={{ marginBottom: 8 }}>
        <label>{label}</label>
      </Col>
      <Col span={24}>
        <TextInput
          style={{ borderRadius: '8px' }}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
        />
      </Col>
    </Row>
  );
};
