import './style.scss';
import { useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { Link } from 'react-router-dom';

import dataCardNFT from 'pages/landing/Explore/dataCardNFT';

import BusinessType from 'components/pd-modal/business-type';
import CardNFT from 'components/landing-components/CardNFT';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import { DashboardContainer } from 'components/dashboard-container';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';

import BgCard from 'assets/images/bg-card.png';
import balanceIcon from 'assets/icon/balance-icon.svg';

export function InvestorHome() {
  const [modal, setModal] = useState({ type: '', visible: false });

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleOpenModal = () => {
    setModal({ type: 'businessType', visible: true });
  };

  const modalContent = {
    businessType: <BusinessType />,
    // success: <Success />,
  };

  return (
    <Layout className="pd-cms-home">
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          My Business
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer>
        <div>
          <Row className="pd-margin-bottom-md ">
            <Col className="pd-padding-md balance-wrapper pd-margin-right-md">
              <Row align="middle">
                <div className="pd-margin-right-md">
                  <div>Total Balance</div>
                  <PdTitle size="small">IDRT 120.000.000</PdTitle>
                </div>
                <img src={balanceIcon} alt="balance icon" />
              </Row>
            </Col>
            <PdButton color="blue">Withdraw</PdButton>
          </Row>
          <Col span={24}>
            <Row justify="space-between" gutter={24}>
              {[1, 2, 3, 4].map((data) => (
                <Col key={data} className="gutter-row" span={6}>
                  <div className="card-counter">
                    <div>
                      <PdTitle size="extra-small">Total My Business</PdTitle>
                      <h1>10</h1>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Row
            align="middle"
            justify="center"
            className="pd-margin-top-md pd-margin-bottom-xl">
            <Col span={24}>
              <Row justify="space-between" gutter={[16, 16]}>
                {dataCardNFT.map((data) => (
                  <Col span={8} className="gutter-row" key={data.id}>
                    <Link to={'/investor/nft-detail/1'}>
                      <CardNFT
                        className={'pd-margin-top-lg'}
                        from="investor"
                        cover={
                          <img
                            src={BgCard}
                            alt="bg-card"
                            style={{ borderRadius: '12px 12px 0 0' }}
                          />
                        }
                        data={data}
                      />
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </DashboardContainer>
    </Layout>
  );
}

export default { InvestorHome };
