import { Switch } from 'react-router-dom';
import { Layout } from 'antd';

import { useScrollToTop } from 'hooks/use-scroll-top';

import { PdNavbar } from 'components/pd-navbar';
import { Sidebar } from 'components/sidebar';
import AuthenticatedGuardRoute from 'components/guard/authenticated';

import { InvestorHome } from 'pages/dashboard/Investor/investor-home';
import { DetailBusiness } from 'pages/dashboard/Investor/detail-business';

const InvestorRouteWrapper = () => {
  useScrollToTop();
  return (
    <Switch>
      <>
        <Layout className="pd-cms">
          <Sidebar from="investor" />
          <Layout>
            <PdNavbar from="investor" />
            <AuthenticatedGuardRoute
              destinationToRedirect="/explore"
              exact
              path="/investor/my-business"
              component={InvestorHome}
            />
            <AuthenticatedGuardRoute
              destinationToRedirect="/explore"
              exact
              path="/investor/nft-detail/:id"
              component={DetailBusiness}
            />
          </Layout>
        </Layout>
      </>
    </Switch>
  );
};

export default InvestorRouteWrapper;
