import { Collapse } from 'antd';

const { Panel } = Collapse;

const PdAntdCollapse = ({ image = '', text = '', data = [] }) => {
  return (
    <Collapse expandIconPosition="end" style={{ width: '100%' }}>
      <Panel
        header={
          <>
            <img src={image} alt="sea-icon" style={{ marginRight: '.5rem' }} />
            {text}
          </>
        }>
        <p>{data}</p>
      </Panel>
    </Collapse>
  );
};

export default PdAntdCollapse;
