import { Route, Redirect } from 'react-router-dom';

/**
 *
 * @param {object} props
 * @param {any} props.component
 * @param {string} props.path
 * @returns
 */
function NotAuthenticatedGuardRoute(props) {
  const { component: WrapperComponent, path, ...rest } = props;

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        // Perhaps it's better get auth status from authentication context.
        // so we can be more flexible of determining if a user logged in or not
        // instead relying only from cms_token
        return !localStorage.getItem('company_token') ? (
          <WrapperComponent {...props} />
        ) : (
          <Redirect to="/company/dashboard" />
        );
      }}
    />
  );
}

export default NotAuthenticatedGuardRoute;
