import { Form } from 'antd';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import PdTitle from 'components/pd-title';

import { isAlpha, isSpecial } from 'utils/validate-password';

import './style.scss';

/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

export default function Password({ onFinish = () => {} }) {
  async function handleValidatePassword(rule, value) {
    if (value) {
      if (!isAlpha(value)) {
        throw 'Please input a minimum of 1 uppercase letter';
      } else if (!isSpecial(value)) {
        throw 'Please input a minimum of 1 special character';
      }
    }
  }

  return (
    <div className="pd-shared-modal-password">
      <PdTitle align="center" color="black" size="small">
        Set a New Password
      </PdTitle>
      <div className="subtitle">
        Enter the email address you use for Outschool, and we&apos;ll help you
        set a new password.
      </div>

      <div className="content">
        <Form
          id="password-form"
          layout="vertical"
          className="form-wrapper"
          onFinish={onFinish}
          requiredMark={false}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              {
                min: 8,
                message: 'Please input a minimum of 8 character',
              },
              {
                validator: handleValidatePassword,
              },
            ]}>
            <PdAntdInput variant="password" placeholder="New password" />
          </Form.Item>
          <Form.Item
            dependencies={['password']}
            name="confirm"
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                },
              }),
            ]}>
            <PdAntdInput variant="password" placeholder="Confirm password" />
          </Form.Item>
          <Form.Item className="btn-finish">
            <PdButton style={{ width: '100%' }} htmlType="submit">
              Continue
            </PdButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
