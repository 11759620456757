import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';

import { ethers } from 'ethers';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';

const Navbar = () => {
  const history = useHistory();
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [userBalance, setUserBalance] = useState(null);

  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log('MetaMask Here!');
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((result) => {
          if (result.length > 0) {
            history.push('/investor/my-business');
            accountChangedHandler(result[0]);
            getAccountBalance(result[0]);
          } else {
            setIsWalletConnected(false);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    } else {
      message.info('Need to install MetaMask');
      window.open(
        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
        '_blank'
      );
    }
  };

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    localStorage.setItem('wallet_address', JSON.stringify(newAccount));
    getAccountBalance(newAccount.toString());
  };

  const getAccountBalance = (account) => {
    window.ethereum
      .request({ method: 'eth_getBalance', params: [account, 'latest'] })
      .then((balance) => {
        setUserBalance(ethers.utils.formatEther(balance));
      })
      .catch((error) => {
        //check if there is no connected account
        if (error.code === -32602) {
          window.location.reload();
          return;
        }
        message.error(error.message);
      });
  };

  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    window.location.reload();
  };

  // listen for account changes
  if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on('accountsChanged', accountChangedHandler);
    window.ethereum.on('chainChanged', chainChangedHandler);
  }

  const isMetaMaskConnected = async () => {
    const accounts = await provider.listAccounts();

    if (accounts.length) {
      // metamask is connected
      setIsWalletConnected(true);
    } else {
      // metamask is not connected
      setIsWalletConnected(false);
      localStorage.removeItem('wallet_address');
    }
  };

  useEffect(() => {
    isMetaMaskConnected();
  }, []);

  return (
    <Row
      className="w-100 h-nav pd-shadow-navbar"
      align="middle"
      justify="center">
      <Col span={22}>
        <Row className="w-100" align="middle" justify="space-between">
          <Col span={12}>Logo</Col>
          <Col span={12}>
            <Row align="middle" justify="space-between">
              <Col span={14}>
                <PdAntdInput
                  variant="search"
                  placeholder="Search"
                  className="h-input"
                />
              </Col>
              <Col span={8}>
                <PdButton
                  className="pd-background-button pd-text-white pd-button-nav h-button"
                  onClick={connectWalletHandler}>
                  {isWalletConnected ? 'Wallet Connected' : 'Connect Wallet'}
                </PdButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Navbar;
