/**
 * TODO: there's currently no syncronization between the highlighted menu with the pathname
 * if a user refresh a page, the menu highlight is gone
 */
import { Menu, Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { menuOptionsInvestor, menuOptionsCompany } from './menuOptions';

import tykoonLogo from 'assets/images/Logo.png';

import './style.scss';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;
const { Title } = Typography;

function renderMenu(opt, parent = '') {
  const {
    name = '',
    link = '',
    type = 'Menu',
    children = [],
    image = '',
  } = opt;

  if (type === 'Menu') {
    return (
      <Item key={`${parent}${name}`}>
        <img src={image} alt={image} style={{ marginRight: '.5rem' }} />
        <Link to={link}>{name}</Link>
      </Item>
    );
  } else {
    return (
      <SubMenu key={name} title={<span>{name}</span>}>
        {children.map((opt) => renderMenu(opt, `${name}-`))}
      </SubMenu>
    );
  }
}

export function Sidebar({ from = 'company' }) {
  async function handleLogout() {
    const cms_token = localStorage.getItem('company_token');
    const wallet_address = localStorage.getItem('wallet_address');
    
    if (from === 'company' && cms_token) {
      window.location.reload();
      localStorage.removeItem('company_token');
      localStorage.removeItem('current_user');
    } else if (from === 'investor' && wallet_address) {
      window.location.reload();
      localStorage.removeItem('wallet_address');
    }
  }

  return (
    <Sider width="300" className="pd-cms-sidebar">
      <div className="wrapper">
        <div className="menu">
          <Title level={3}>
            <Link to={`/`}>
              <img src={tykoonLogo} alt="logo" />
            </Link>
          </Title>
          <Menu mode="inline">
            {from === 'company'
              ? menuOptionsCompany.map((opt) => renderMenu(opt))
              : menuOptionsInvestor.map((opt) => renderMenu(opt))}
          </Menu>
        </div>
        <div className="user-badge">
          <Title level={4} onClick={() => handleLogout()}>
            <Link to={'/'}>Logout</Link>
          </Title>
        </div>
      </div>
    </Sider>
  );
}

export default { Sidebar };
