import { Col, Row } from 'antd';
import { PdButton } from 'components/pd-button';
import TSelect from '../Select';
import { TextInputWithLabel } from '../TextInputWithLabel';


const Hero = () => {
  return (
    <Row align="middle" justify="center">
      <Col span={22}>
        <h3 className="pd-margin-top-xl pd-margin-bottom-xl pd-text-explore">
          Explore Business
        </h3>
        <Row align="middle" justify="center" className="pd-margin-bottom-md">
          <Col span={20}>
            <Row align="bottom" justify="space-between">
              <Col span={5}>
                <TextInputWithLabel
                  label="Min Price"
                  name="min-price"
                  type="text"
                  placeholder={'IDRT'}
                  className="h-button-primary"
                />
              </Col>
              <Col span={5}>
                <TextInputWithLabel
                  label="Max Price"
                  name="max-price"
                  type="text"
                  placeholder={'IDRT'}
                  className="h-button-primary"
                />
              </Col>
              <Col span={5}>
                <TSelect
                  label="Category"
                  defaultValue={'All Categories'}
                  options={[{ label: 'Category 1', value: 'Category 1' }]}
                  className="w-full"
                />
              </Col>
              <Col span={5}>
                <TSelect
                  label="Sort By"
                  defaultValue={'Default'}
                  options={[{ label: 'Category 1', value: 'Category 1' }]}
                  className="w-full"
                />
              </Col>
              <Col span={3}>
                <PdButton
                  type={'primary'}
                  className="pd-button-primary h-button-primary">
                  Apply Filter
                </PdButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </Col>
    </Row>
  );
};

export default Hero;
