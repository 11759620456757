import seaIcon from 'assets/images/simple-icons_opensea.png';

const dataCardNFT = [
  {
    id: 1,
    priceOfNFT: 'IDRT 12.000.000',
    expectedROI: '2 Month',
    NFTDistributed: '200 of 360',
    numberOfNFTSold: '200',
    expectedAPY: '10%',
    dueDate: '2022-02-22',
    buttonTitle: 'Buy Now',
    percent: 80,
  },
  {
    id: 2,
    priceOfNFT: 'IDRT 12.000.000',
    expectedROI: '2 Month',
    NFTDistributed: '200 of 360',
    numberOfNFTSold: '200',
    expectedAPY: '10%',
    dueDate: '2022-02-22',
    buttonTitle: 'Buy via OpenSea',
    percent: 80,
    icon: <img src={seaIcon} alt="sea-icon" style={{ marginRight: 10 }} />,
  },
  {
    id: 3,
    priceOfNFT: 'IDRT 12.000.000',
    expectedROI: '2 Month',
    NFTDistributed: '200 of 360',
    numberOfNFTSold: '200',
    expectedAPY: '10%',
    dueDate: '2022-02-22',
    buttonTitle: 'Buy Now',
    percent: 80,
  },
  {
    id: 4,
    priceOfNFT: 'IDRT 12.000.000',
    expectedROI: '2 Month',
    NFTDistributed: '200 of 360',
    numberOfNFTSold: '200',
    expectedAPY: '10%',
    dueDate: '2022-02-22',
    buttonTitle: 'Buy Now',
    percent: 80,
  },
  {
    id: 5,
    priceOfNFT: 'IDRT 12.000.000',
    expectedROI: '2 Month',
    NFTDistributed: '200 of 360',
    numberOfNFTSold: '200',
    expectedAPY: '10%',
    dueDate: '2022-02-22',
    buttonTitle: 'Buy via OpenSea',
    percent: 80,
    icon: <img src={seaIcon} alt="sea-icon" style={{ marginRight: 10 }} />,
  },
  {
    id: 6,
    priceOfNFT: 'IDRT 12.000.000',
    expectedROI: '2 Month',
    NFTDistributed: '200 of 360',
    numberOfNFTSold: '200',
    expectedAPY: '10%',
    dueDate: '2022-02-22',
    buttonTitle: 'Buy Now',
    percent: 80,
  },
];

export default dataCardNFT;
