import { Col, Form, Row } from 'antd';

import { PdButton } from 'components/pd-button';
import PdTitle from 'components/pd-title';
import identity from 'lodash';

import ask from 'assets/icon/ask.png';
import './style.scss';

/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

export default function AskVerif({
  onFinish = identity,
  title = 'Are you sure to verify now?',
  desc = 'Verify your account',
}) {
  return (
    <div className="pd-guest-modal-verif">
      <div className="header">
        <img src={ask} alt="ask-img" />
      </div>
      <div className="content">
        <PdTitle size="extra-small">{title}</PdTitle>
        <p>{desc}</p>
        <Form
          id="forgot-form"
          layout="vertical"
          className="form-wrapper"
          // onFinish={onFinish}
          requiredMark={false}>
          <Form.Item>
            <Row justify="center" gutter={20}>
              <Col>
                <PdButton
                  onClick={() => onFinish('no')}
                  htmlType="button"
                  color="blue"
                  type="default">
                  Cancel
                </PdButton>
              </Col>

              <Col>
                <PdButton
                  onClick={() => onFinish('yes')}
                  htmlType="button"
                  color="blue">
                  Submit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
