import { forwardRef } from 'react';
import { Select } from 'antd';

import './style.scss';

const { Option } = Select;

/**
 *
 * @param {Object} props
 * @param {Array} props.dataSet
 * @param {String} props.optKey
 * @param {String} props.optValue
 * @param {String} props.optContent
 * @returns
 *
 */

export const PdAntdSelect = forwardRef(
  (
    {
      dataSet = [],
      optKey = '',
      optValue = '',
      optContent = '',
      className = '',
      ...props
    },
    ref
  ) => {
    return (
      <Select ref={ref} {...props} className={`pd-antd-select ${className}`}>
        {dataSet.map((el, idx) => (
          <Option key={el[optKey] ?? idx} value={el[optValue]}>
            {el[optContent]}
          </Option>
        ))}
      </Select>
    );
  }
);

PdAntdSelect.displayName = 'PdAntdSelect';

export default { PdAntdSelect };
