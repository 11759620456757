import { HeartOutlined } from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { PdButton } from 'components/pd-button';
import TProgress from '../Progress';

import seaIcon from 'assets/icon/nft-sell.png';

const CardNFT = ({ data, cover, from = 'investor' }) => {
  const {
    icon,
    className,
    percent,
    priceOfNFT,
    expectedROI,
    NFTDistributed,
    numberOfNFTSold,
    expectedAPY,
    dueDate,
    buttonTitle,
  } = data;

  return (
    <Card
      style={{ borderRadius: '12px', position: 'relative' }}
      cover={cover}
      className={`${className}`}>
      <div
        style={{ position: 'absolute', top: 10, right: 20, cursor: 'pointer' }}>
        <HeartOutlined style={{ color: '#fff', fontSize: '20px' }} />
      </div>
      <Row align="top" justify="center">
        <Col span={24}>
          <p className="pd-text-secondary">Business Name</p>
          {from === 'explore' && (
            <>
              <Row align="middle" justify="space-between">
                <Col span={12}>14.000.000</Col>
                <Col span={12} style={{ textAlign: 'end' }}>
                  20.000.000
                </Col>
              </Row>
              <TProgress percent={percent} />
            </>
          )}
          <Row align="middle" justify="space-between" gutter={24}>
            <Col span={12} className="gutter-row">
              {from === 'investor' ? (
                <>
                  <p className="font-400 pd-text-body-card">Last Period</p>
                  <p className="font-500 pd-text-body-card-2">{priceOfNFT}</p>
                  <p className="font-400 pd-text-body-card">Dividends Period</p>
                  <p className="font-500 pd-text-body-card-2">{expectedROI}</p>
                </>
              ) : (
                <>
                  <p className="font-400 pd-text-body-card">Price of NFT</p>
                  <p className="font-500 pd-text-body-card-2">{priceOfNFT}</p>
                  <p className="font-400 pd-text-body-card">Expected ROI</p>
                  <p className="font-500 pd-text-body-card-2">{expectedROI}</p>
                  <p className="font-400 pd-text-body-card">NFT Distributed</p>
                  <p className="font-500 pd-text-body-card-2">
                    {NFTDistributed}
                  </p>
                </>
              )}
            </Col>
            <Col span={12} className="gutter-row">
              {from === 'investor' ? (
                <>
                  <p className="font-400 pd-text-body-card">
                    Number of NFT Own
                  </p>
                  <p className="font-500 pd-text-body-card-2">
                    {numberOfNFTSold}
                  </p>
                  <p className="font-400 pd-text-body-card">This Period</p>
                  <p className="font-500 pd-text-body-card-2">{expectedAPY}</p>
                </>
              ) : (
                <>
                  <p className="font-400 pd-text-body-card">
                    Number of NFT Sold
                  </p>
                  <p className="font-500 pd-text-body-card-2">
                    {numberOfNFTSold}
                  </p>
                  <p className="font-400 pd-text-body-card">Expected APY</p>
                  <p className="font-500 pd-text-body-card-2">{expectedAPY}</p>
                  <p className="font-400 pd-text-body-card">Due Date</p>
                  <p className="font-500 pd-text-body-card-2">{dueDate}</p>
                </>
              )}
            </Col>
          </Row>
          {from === 'investor' ? (
            <PdButton
              type={'default'}
              color="blue"
              className="pd-button-primary h-button-primary w-full"
              icon={
                <img src={seaIcon} alt="sea-icon" style={{ marginRight: 10 }} />
              }>
              Sell NFT
            </PdButton>
          ) : (
            <PdButton
              type={'primary'}
              className="pd-button-primary h-button-primary w-full"
              icon={icon}>
              {buttonTitle}
            </PdButton>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default CardNFT;
