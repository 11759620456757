import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Popover } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';

import PdAntdHeader from 'components/pd-antd-header';

import backtohome from '../../assets/icon/backtohome.svg';
import logout from '../../assets/icon/logout.svg';
import marketplace from '../../assets/icon/marketplace.svg';
import notification from '../../assets/icon/notification.svg';

import './style.scss';

import _ from 'lodash';

export const PdNavbar = ({ from = 'company' }) => {
  const addressWallet = JSON.parse(localStorage.getItem('wallet_address'));
  const [isOpen, setIsOpen] = useState(false);

  async function handleLogout() {
    const cms_token = localStorage.getItem('company_token');
    const wallet_address = localStorage.getItem('wallet_address');

    if (from === 'company' && cms_token) {
      window.location.reload();
      localStorage.removeItem('company_token');
      localStorage.removeItem('current_user');
    } else if (from === 'investor' && wallet_address) {
      window.location.reload();
      localStorage.removeItem('wallet_address');
    }
  }

  return (
    <PdAntdHeader>
      <div></div>
      <div className="nav-content-wrapper">
        {from === 'company' && (
          <Popover
            placement="bottomRight"
            content={'You dont have any notifications'}
            trigger="click">
            <Badge dot>
              <img src={notification} alt="notif icon" className="notif-img" />
            </Badge>
          </Popover>
        )}
        <div className="profile" onClick={() => setIsOpen(!isOpen)}>
          <Avatar shape="circle" icon={<UserOutlined />} />
          <div>
            {from === 'company'
              ? 'Galih Permana'
              : _(addressWallet).truncate({ length: 20 })}
          </div>
          <DownOutlined />

          {isOpen && (
            <div className="profile-menu">
              <div className="option">
                <Link to="/">
                  <img src={backtohome} alt="home icon" />
                  Back to Home
                </Link>
              </div>
              <div className="option">
                <Link to="/explore">
                  <img src={marketplace} alt="home icon" />
                  Marketplace
                </Link>
              </div>

              <div className="option" onClick={handleLogout}>
                <img src={logout} alt="home icon" />
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </PdAntdHeader>
  );
};

export default { PdNavbar };
