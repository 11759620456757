import { Progress } from 'antd'


const TProgress = ({ percent, showInfo = false }) => {
  return (
    <Progress
      strokeColor={'#9A70FF'}
      percent={percent}
      showInfo={showInfo}
    />
  )
}

export default TProgress