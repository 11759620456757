import { useHistory } from 'react-router-dom';

const LandingPage = () => {
  const history = useHistory();
  history.push(`${process.env.PUBLIC_URL}/homepage/index.html`);
  window.location.reload();
  return <></>;
};

export default LandingPage;
