import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';

import CompanyRouteWrapper from 'routes/CompanyRoute';
import InvestorRouteWrapper from 'routes/InvestorRoute';

import AuthenticationContextProvider from './contexts/authentication';

import { Explore } from 'pages/landing/Explore';
import { LoginPageForm } from 'pages/login';
import LandingPage from 'pages/landing/Explore/landing';
import NotAuthenticatedGuardRoute from 'components/guard/notAuthenticated';

import 'config/antd.less';
import '@ant-design/compatible/assets/index.css';
import 'App.scss';

// Weird that we mix UI and routes
function DashboardAccount() {
  return (
    <Switch>
      <>
        <NotAuthenticatedGuardRoute
          exact
          path="/login"
          component={LoginPageForm}
        />
        <NotAuthenticatedGuardRoute
          exact
          path="/register"
          component={LoginPageForm}
        />

        <Route exact path="/explore" component={Explore} />
        <Route exact path="/" component={LandingPage} />
      </>
    </Switch>
  );
}

// TODO: 404 page
// TODO: ErrorBoundary

function App() {
  const history = useHistory();

  return (
    <Router>
      <Switch>
        <AuthenticationContextProvider history={history}>
          <Switch>
            <Route path="/company" component={CompanyRouteWrapper} />
            <Route path="/investor" component={InvestorRouteWrapper} />
            <Route path="/" component={DashboardAccount} />
          </Switch>
        </AuthenticationContextProvider>
      </Switch>
    </Router>
  );
}

export default App;
