import './style.scss';
import { useState } from 'react';
import { Col, Layout, Row } from 'antd';

import BusinessType from 'components/pd-modal/business-type';
import PdAntdCollapse from 'components/pd-antd-collapse';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import { DashboardContainer } from 'components/dashboard-container';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';

import businessDetail from 'assets/icon/business-detail.png';
import companyDoc from 'assets/icon/company-document.svg';
import cctv from 'assets/icon/cctv.svg';
import dummyBusiness from 'assets/images/dummy-detail-business.png';
import listingTable from 'assets/icon/listing-table.svg';
import offerTable from 'assets/icon/offer-table.png';
import transaction from 'assets/icon/transaction.svg';
import seaIcon from 'assets/icon/nft-sell-black.png';
import refresh from 'assets/icon/refresh.svg';
import share from 'assets/icon/share.svg';
import option from 'assets/icon/option.svg';

export function DetailBusiness() {
  const [modal, setModal] = useState({ type: '', visible: false });

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleOpenModal = () => {
    setModal({ type: 'businessType', visible: true });
  };

  const modalContent = {
    businessType: <BusinessType />,
    // success: <Success />,
  };

  return (
    <Layout className="pd-cms-home">
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Detail Business
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer>
        <Row gutter={24}>
          <Col span={12}>
            <Row className="pd-margin-bottom-md">
              <img
                src={dummyBusiness}
                alt="sea-icon"
                style={{ width: '100%' }}
              />
            </Row>
            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse
                image={companyDoc}
                text="Company Document"
                data={'test'}
              />
            </Row>
            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse
                image={businessDetail}
                text="Business Detail"
                data={'test'}
              />
            </Row>
            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse image={cctv} text="CCTV" data={'test'} />
            </Row>
          </Col>

          <Col span={12}>
            <Row justify="space-between">
              <Col>
                <p>Company Inc.</p>
                <PdTitle size="small">Business NFT #1</PdTitle>
                <p>Current Owner 0x0234..</p>
              </Col>
              <Col>
                <Row className="button-wrapper">
                  <div className="button-detail">
                    <img src={refresh} alt="refresh" />
                  </div>
                  <div className="button-detail">
                    <img src={share} alt="refresh" />
                  </div>
                  <div className="button-detail">
                    <img src={option} alt="refresh" />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row
              justify="space-between"
              gutter={[24, 24]}
              className="pd-margin-bottom-md">
              {[1, 2, 3, 4, 5, 6].map((data) => (
                <Col key={data} className="gutter-row" span={8}>
                  <div className="card-counter">
                    <div>
                      <p>Total My Business</p>
                      <h1>10</h1>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <Row
              justify="space-between"
              align="middle"
              className="row-card pd-margin-bottom-md">
              <div>
                <PdTitle size="extra-small">IDRT 2.000.000</PdTitle>
                <div>From IDRT 20.000.000</div>
              </div>
              <div>
                <PdTitle size="extra-small">2D 22h 22m</PdTitle>
                <div>Time Left</div>
              </div>
            </Row>
            <Row
              justify="space-between"
              className="pd-margin-bottom-md row-card">
              <Col>
                <div></div>
              </Col>
              <Col>
                <PdButton
                  type={'default'}
                  color="black"
                  className="pd-button-primary h-button-primary w-full"
                  icon={
                    <img
                      src={seaIcon}
                      alt="sea-icon"
                      style={{ marginRight: 10 }}
                    />
                  }>
                  Sell NFT
                </PdButton>
              </Col>
            </Row>
            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse
                image={listingTable}
                text="Listing Table"
                data={'test'}
              />
            </Row>
            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse
                image={offerTable}
                text="Offer Table"
                data={'test'}
              />
            </Row>
            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse
                image={transaction}
                text="Transaction"
                data={'test'}
              />
            </Row>
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

export default { DetailBusiness };
