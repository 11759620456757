import { useState } from 'react';
import { message, Upload } from 'antd';
import { Icon } from '@ant-design/compatible';
import { get, noop } from 'lodash';

// import uploadApi from 'api/upload';

import './style.scss';

//allowedExtension = ['pdf','mp4','jpg','png','jpeg','gif'];

/**
 *
 * @param {Object} props
 * @param {() => {}} props.afterUpload - callback after beforeUpload is triggered
 * @param {string} props.alt- text if image isn't rendered
 * @param {string} props.icon - type of icon antd to display
 * @param {object} props.imgStyle - style of image
 * @param {('profile_pictures' | 'company_assets' | 'materials' | 'classroom_attachment' | 'classroom_assets')} props.purpose - beforeUpload params => based on BE
 * @param {('image' | 'video')} props.type - type of media
 * @param {string} props.url - media url to display afterUpload
 */

export default function PdUploader({
  afterUpload = () => {},
  alt = '',
  icon = 'inbox',
  imgStyle = {},
  purpose = '',
  type = 'image',
  url = '',
  ...props
}) {
  const [uploading, setUploading] = useState(false);

  const UPLOAD_TEXT = {
    image: 'Click or drag to import an image',
    video: 'Click or drag to import a video',
  };

  // create the jsx component here for the content
  const ImageContent = () => (
    <img className="img-container" style={imgStyle} alt={alt} src={url} />
  );

  const VideoContent = () => (
    <video className="img-container">
      <source src={url} alt={alt}></source>
    </video>
  );

  // Define which content based on type props here
  const CONTENT = {
    image: ImageContent,
    video: VideoContent,
  };

  const MediaContent = CONTENT[type];

  async function beforeUpload(file, purpose) {
    try {
      setUploading(true);
      const ext = file.type.split('/')[1];
      // const {
      //   data: { url },
      // } = await uploadApi.getUploadURL(ext, purpose);

      // await uploadApi.uploadImage(file, url.uploadUrl);
      message.success('Upload Success');
      afterUpload(url.objectUrl);
    } catch (err) {
      const errMessage = get(err, 'response.data.message');
      if (errMessage) {
        message.error(errMessage);
      } else {
        message.error('Cannot connect to server, please check connection');
      }
    } finally {
      setUploading(false);
    }
  }
  return (
    <Upload.Dragger
      beforeUpload={(file) => {
        beforeUpload(file, purpose);
      }}
      //prevent upload to call the web page url
      customRequest={noop}
      className="pd-uploader"
      showUploadList={false}
      {...props}>
      {!url || uploading ? (
        <>
          <p className="ant-upload-drag-icon">
            <Icon type={uploading ? 'loading' : icon} />
          </p>
          <p className="ant-upload-text">{UPLOAD_TEXT[type]}</p>
        </>
      ) : (
        <MediaContent />
      )}
    </Upload.Dragger>
  );
}
